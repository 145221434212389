import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import PromotionComp from '../Components/PromotionComp'

const Promotions = () => {
  return (
    <>
    <Navbar/>
    <PromotionComp/>
    <Footer/>
    </>
  )
}

export default Promotions