import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import NearbyAttraction from '../Components/NearbyAttraction'
import { NearbyData } from '../data'

const ContactUs = () => {
  return (
    <>
    <Navbar/>
    <NearbyAttraction products = {NearbyData}/>
    <Footer/>
    </>
  )
}

export default ContactUs