import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import IIITDM1 from '../assets/iiitdm_images/iiitdm1.jpg';
import IIITDM2 from '../assets/iiitdm_images/iiitdm2.jpg';
import IIITDM3 from '../assets/iiitdm_images/iiitdm3.jpg';
import { Link } from 'react-router-dom'
import { maxitab } from '../responsive'

const Container = styled.div`
    width : 98.5vw;
    height : auto;
    margin-bottom : 50px;
`
const Wrapper = styled.div`
    right : 0px;
    width : 100%;
    display : flex;
    ${maxitab({
        flexDirection : "column",
    })}
`

const SliderContainer = styled.div`
  flex : 1;
  width: auto; 
  display : flex;
  overflow : hidden;
`

const SliderWrapper = styled.div`
    display : flex;
`

const Slide = styled.img`
    transition: all 1.5s ease;
    width : 50vw;
    object-fit: zoom;
    transform: translateX(${props => props.index * -50}vw);
    ${maxitab(css`
    transform: translateX(${props => props.index * -25}vw);
  `)}
`;


const InfoContainer = styled.div`
    padding : 2% 2%;
    background-color : #efefef;
    flex : 1.2;
    overflow-y : auto;
    &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: white; 
      }
      &::-webkit-scrollbar-thumb {
        background: #214B8C;
        border-radius : 10px;
      }
    border-top : 10px solid #214B8C;
`
const Title = styled.h2`
    font-size : 35px;
    color : #214B8C;
`
const Desc1 = styled.p`
    text-align : justify;
    color : #214B8C;
    line-height : 25px;
`
const Desc2 = styled.p`
    text-align : justify;
    color : #214B8C;
    line-height : 25px;
`

const Button = styled.button`
    border : none;
    color : white;
    padding : 15px 22px;
    font-size : 18px;
    background-color : #214B8C;
    font-weight : bold;
    border-radius : 10px;
    cursor : pointer;
    transition :  all 0.25s ease-out;
    &:hover{
        transform : scale(1.02);
        color : #214B8C;
        background-color : white;
        box-shadow : 0px 0px 10px 2px #214B8C;
        transition :  all 0.25s ease-in;
    }
`

const AboutIIITDM = () => {

    const [index, setIndex] = useState(0);

    const images = [IIITDM1, IIITDM2, IIITDM3]; 

    useEffect(() => {
      const interval = setInterval(() => {
          if(index === 0 || index !== 2) {
              setIndex(0);
              setIndex(index + 1);
          }
          else{
              setIndex(0);
          }
      }, 4000);
  
      return () => clearInterval(interval);
    }, [index]);

  return (
    <Container id='AboutIIITDM'>
        <Wrapper>
        <SliderContainer>
                {images.map((image, idx) => (
                    <SliderWrapper key={idx}>
                        <Slide src={image} index = {index}/>
                    </SliderWrapper>
                ))}
            </SliderContainer>
            <InfoContainer>
            <Title>| About IIITDM Kancheepuram</Title>
            <Desc1>IIITDM Kancheepuram is an Institute of National Importance established in 2007 and fully funded by MoE, Govt. of India with the mandate of offering design and manufacturing oriented engineering education and research. IIITDMs are a result of the country’s vision to produce next generation engineers capable of designing innovative products. The curriculum adopts information science based modern tools and techniques along with an inter-disciplinary approach with a focus on experiential learning.
            </Desc1>
            <Desc2>The Institution offers B.Tech programmes in Computer Science and Engineering, Computer Science and Engineering with Major in AI, Electronics and Communication Engineering, Mechanical Engineering and Smart Manufacturing. At the postgraduate level, M.Tech programmes in  Data Science and AI, Communication Systems, Microelectronics and VLSI, Mechanical Systems Design and Smart Manufacturing are offered. Apart from these, dual degree programmes, M.Des in Integrated Product Design are also offered along with PhD in various streams of engineering and sciences. With mastery in domain specific design, engineering skills and required managerial expertise, our graduates are moulded to entrepreneur organizations involved in the design and manufacture of commercially successful electronic and mechanical products.
            </Desc2>
            <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://www.iiitdm.ac.in/" target='_blank'> <Button>Explore more</Button> </Link>
            </InfoContainer>
        </Wrapper>
    </Container>
  )
}

export default AboutIIITDM