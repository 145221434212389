import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import SponsorShipComp from '../Components/SponsorShipComp'

const SponsorShip = () => {
  return (
    <>
    <Navbar/>
    <SponsorShipComp/>
    <Footer/>
    </>
  )
}

export default SponsorShip