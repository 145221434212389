import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import StudentAward from '../Components/StudentAward'

const ContactUs = () => {
  return (
    <>
    <Navbar/>
    <StudentAward/>
    <Footer/>
    </>
  )
}

export default ContactUs