import React from 'react';
import styled from 'styled-components';
import { AwardData } from '../data';
import { miniminimobile, minitab } from '../responsive';

const Container = styled.div``;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.img`
    margin: 0px 30px;
    ${minitab({
        width: '20vw',
    })}
    ${miniminimobile({
        width: '17vw',
    })}
`;

const Heading = styled.h2`
    font-size: 40px;
    color: #214b8c;
    padding-bottom: 30px;
    text-align: center;
`;

const Descriptions = styled.p`
    text-align: center;
    font-size: 20px;
    color: #214b8c;
`;

const Wrapper = styled.div`
    display: flex;
    ${minitab({
        flexDirection: 'column-reverse',
        alignItems: 'center',
    })}
`;

const Left = styled.div`
    flex: 2;
    padding: 20px;
    background-color: #efefef;
    height: auto;
`;

const AwardTitle = styled.h2`
    text-align: center;
    font-size: 30px;
    color: #214b8c;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
`;

const GivenBy = styled.p`
    font-size: 20px;
    color: #214b8c;
    width: 95%;
    text-align: justify;
`;
const Criteria = styled.p`
    font-size: 20px;
    color: #214b8c;
    width: 95%;
    text-align: justify;
`;

const Award = styled.p`
    font-size: 20px;
    color: #214b8c;
    width: 95%;
    text-align: justify;
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 0px;
    background-color: #214b8c;
    border-radius: 0px 40px 40px 0px;
    padding: 0px 20px;
    margin-right: 30px;
    ${minitab({
        marginRight: '0px',
        borderRadius: '40px 40px 0px 0px',
        width: '50vw',
        padding: '20px',
    })}
`;

const Image = styled.img`
    border-radius: 50%;
    width: 15vw;
    margin: 20px;
    height: 15vw;
    border: 5px solid white;
`;

const Name = styled.p`
    font-size: 25px;
    color: white;
    font-weight: bold;
    line-height: 25px;
    text-align: center;
`;

const Details = styled.p`
    font-size: 18px;
    color: white;
    line-height: 25px;
    text-align: center;
`;

const StudentAward = () => {
  const parseCashAmount = (text) => {
    const match = text.match(/(\d+)/);
    return match ? parseInt(match[0], 10) : 0;
  };

  const sortedAwards = [...AwardData].sort((a, b) => {
    const cashA = parseCashAmount(a.award);
    const cashB = parseCashAmount(b.award);
    return cashB - cashA;
  });

  const AwardsDetails = (text) => {
    const words = text.split(' ');
    const index = words.findIndex((word) => /cash/i.test(word));
    if (index !== -1) {
      return (
        <>
          {words.slice(0, index).join(' ')} <b>{words.slice(index).join(' ')}</b>
        </>
      );
    }
    return text;
  };

  return (
    <Container>
      <Title>
        <Logo src="https://i.ibb.co/cYYsG2y/icons8-award-100.png" />
        <Heading>AWARDS</Heading>
        <Logo src="https://i.ibb.co/cYYsG2y/icons8-award-100.png" />
      </Title>
      <Descriptions>
        Numerous Awards and Travel Grants are funded through the generous grants received from various senior members of the IEEE and WAMS.
      </Descriptions>
      <br />
      <br />
      {sortedAwards.map((item, index) => (
        <React.Fragment key={item.id}>
          <Wrapper>
            <Left>
              <AwardTitle>
                {index + 1}. {item.awardtitle}
              </AwardTitle>
              <GivenBy>{item.givenBy}</GivenBy>
              <Criteria>
                <b>Criteria: </b>
                {item.criteria}
              </Criteria>
              <Award>
                <b>Award: </b>
                {AwardsDetails(item.award)}
              </Award>
            </Left>
            <Right>
              <Image src={item.image} />
              <Name>{item.name}</Name>
              <Details>{item.details}</Details>
            </Right>
          </Wrapper>
          <br />
          <br />
        </React.Fragment>
      ))}
    </Container>
  );
};

export default StudentAward;
