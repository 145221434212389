import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import { StayData } from '../data';
import { maxitab, minitab } from '../responsive';

const HeadingContainer = styled.div``;

const Heading = styled.h2`
  text-align: center;
  font-size: 40px;
  color: #214b8c;
`;

const HeadingDescription = styled.p`
  text-align: center;
  font-size: 25px;
  font-weight : bold;
  color: #214b8c;
`;

const Container = styled.div`
  width: 99vw;
  ${minitab({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })}
`;

const Wrapper = styled.div`
  display: flex;
  height: auto;
  ${minitab({
    flexDirection: 'column',
    alignItems: 'center',
  })}
`;

const Image = styled.img`
  width: 30vw;
  height: 50vh;
  object-fit: cover;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 4px black;
`;

const InfoContainer = styled.div`
  width: 100%;
  height: auto;
  ${minitab({
    textAlign: 'center',
  })}
`;

const Title = styled.h2`
  font-size: 30px;
  color: #214b8c;
  width: 90%;
  ${maxitab({
    fontSize: '25px',
    textAlign: 'center',
    width: '100%',
  })}
`;

const Description = styled.p`
  color: #214b8c;
  width: 80%;
  text-align: justify;
  font-size: 20px;
  ${maxitab({
    fontSize: '16px',
    textAlign: 'center',
    width: '100%',
  })}
`;

const Explore = styled.p`
  color: #214b8c;
  text-align: justify;
  width: 90%;
  font-size: 20px;
  ${maxitab({
    fontSize: '16px',
    textAlign: 'center',
    width: '100%',
  })}
`;

const Button = styled.button`
  border: none;
  color: white;
  padding: 5px 10px;
  font-size: 18px;
  background-color: #214b8c;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.25s ease-out;
  &:hover {
    transform: scale(1.02);
    color: #214b8c;
    background-color: white;
    box-shadow: 0px 0px 10px 2px #214b8c;
    transition: all 0.25s ease-in;
  }
`;

function Staying() {
  const [activeImage, setActiveImage] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage((prevImage) => ({
        ...prevImage,
        index: prevImage.index === 0 ? 1 : 0,
      }));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <HeadingContainer>
        <Heading>Accommodation</Heading>
        <HeadingDescription>
          The following Hotels are situated in and around the conference venue.
        </HeadingDescription>
      </HeadingContainer>
      <Container>
        {StayData.map((item, index) => (<>
          {item.id === 9 ? <HeadingDescription>
            Student Accommodation
        </HeadingDescription>: ""}
          <Wrapper key={index}>
            <Image
              src={activeImage.index === 0 ? item.image1 : item.image2}
              alt={item.name}
            />
            <InfoContainer>
              <Title>{item.name}</Title>
              <Description>
                <b style={{ fontSize: '22px' }}>{(item.id !== 7) ? "Address:" : " "}  </b>
                {item.description}
              </Description>
              {item.id !== 7 ? <Description>
                <b style={{ fontSize: '22px' }}>{(item.id !== 7) ? "Distance from the venue: " : " "}  </b>
                {item.distance}
              </Description> : ""}
              <Explore>
                <b style={{ fontSize: '22px' }}>Explore Website: </b>
                <Link
                  style={{ textDecoration: 'none', zIndex: '2' }}
                  to={item.link}
                  target="_blank"
                >
                  <Button>Show</Button>
                </Link>
              </Explore>
            </InfoContainer>
          </Wrapper></>
        ))}
      </Container>
    </div>
  );
}

export default Staying;
