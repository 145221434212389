import Kartikeyan from './assets/committee_images/kartikeyan.jpg';
import Sudhakar from './assets/committee_images/sudhakar.jpeg';
import Selvaraj from './assets/committee_images/selvaraj.jpeg';
import Chitti from './assets/committee_images/chitti.jpeg';
import PremKumar from './assets/committee_images/premkumar.jpeg';
import Srijith from './assets/committee_images/srijith.png';
import Nacer from './assets/committee_images/Nacer-Chahat-c.webp';
import Kahng from './assets/committee_images/Prof S Kahng.jpg';
import Paolo from './assets/committee_images/Dr Paolo Noschese.jpeg';
import Sandeep from './assets/committee_images/Dr Sandeep Chaturvedi.jpeg';
import Rajeev from './assets/committee_images/Mr Rajeev Jyoti.jpeg';
import Rao from './assets/committee_images/C S Rao.jpeg';
import Jiang from './assets/committee_images/Dr Jiang Zhu.jpeg';
import Maryam from './assets/committee_images/Dr Maryam Rofougaran.jpeg';
import Reddy from './assets/committee_images/Dr C J Reddy.jpeg';
import Wu from './assets/committee_images/Dr Ke Wu.jpeg';
import Clency from './assets/committee_images/Dr Clency Lee-Yow.jpeg';
import Rodney from './assets/committee_images/Dr Rodney Waterhouse.gif';
import Poddar from './assets/committee_images/Dr A K Poddar.gif';
import Taimoor from './assets/committee_images/Dr.Taimoor_Khan.jpg';
import Neelakandan from './assets/committee_images/Dr.Neelakandan Rajamohan.jpg';
import Rahul from './assets/committee_images/Dr.Rahul Harishcjandra Meshram.png';
import Malik from './assets/committee_images/Dr Jagannath_Malik.jpeg';
import Somak from './assets/committee_images/Dr Somak Bhattacharyya.jpg';
import Gowrish from './assets/committee_images/Dr Gowrish.jpg';
import Sreenath from './assets/committee_images/sreenath.jpeg';
import Bharathi  from './assets/committee_images/Dr.A Bharathi.jpg';
import Gaurangi  from './assets/committee_images/Dr. Gaurangi Gupta.jpg';
import Binsu  from './assets/committee_images/binsu.jpeg';
import Keerthi  from './assets/committee_images/Ms. S.Keerthi Priya.jpg';
import Elizabeth  from './assets/committee_images/Dr.Elizabeth George.jpeg';
import Rohini  from './assets/committee_images/Dr Rohini.jpeg';
import Uttam  from './assets/committee_images/Dr Uttam M. Pal.jpeg';
import Shiv  from './assets/committee_images/Dr Shiv-Narayan.png';
import Rama  from './assets/committee_images/D.-Rama-Krishna.jpg';
import Desmond  from './assets/committee_images/Dr.Chow Yen Desmond Sim.jpeg';
import Gowri  from './assets/committee_images/gowri.jpeg';
import Prithiviraj  from './assets/committee_images/Prof V. Prithviraj.jpg';
import Sukomal  from './assets/committee_images/Dr Sukomal Dey.jpg';
import Christopher  from './assets/committee_images/Prof Christopher Fumeaux.jpeg';
import Levent  from './assets/committee_images/Prof Levent Sevgi.png';
import Pradhan  from './assets/committee_images/Dr.N.C.Pradhan.jpg';
import Chowdary  from './assets/committee_images/Dr PSR Chowdary.jpeg';
import Dhaval  from './assets/committee_images/Dr Dhaval Pujara.jpg';
import SChristopher  from './assets/committee_images/Dr Christopher-S.webp';
import Avinash  from './assets/committee_images/avinash.jpg';
import Ravipati  from './assets/committee_images/ravipati.png';
import Sameer  from './assets/committee_images/Prof-Sameer-Chakravarthy.jpg';
import Lotfollah  from './assets/committee_images/Prof-Lotfollah-Shafai.jpg';
import Abhishek  from './assets/committee_images/Dr-Abishek-kumar-Jha.png';
import Yuvraj  from './assets/committee_images/yuvraj.jpg';
import Satish  from './assets/committee_images/satish.jpg';
import Jency  from './assets/committee_images/jency.jpg';
import Sahil  from './assets/committee_images/sahil.jpg';
import Jonathan from './assets/committee_images/jonathan.png'
import Zubair from './assets/committee_images/zubair.jpeg'
import Balamati from './assets/committee_images/balamati.jpeg'

import kancheepuram from './assets/NearBy_images/kancheepuram.png'
import Mahabalipuram from './assets/NearBy_images/mahabalipuram.jpg'
import Marina from './assets/NearBy_images/marina.jpg'
import Dakshina from './assets/NearBy_images/dakshina.jpg'
import Santhome from './assets/NearBy_images/santhome.png'
import Birla from './assets/NearBy_images/birla.jpg'
import Valluvar from './assets/NearBy_images/valluvar.jpg'
import Covelong from './assets/NearBy_images/Covelong.jpg'
import AnnaZoo from './assets/NearBy_images/annaZoo.jpg'
import VgpKingdom from './assets/NearBy_images/vgpkingdom.jpeg'
import Crocodile from './assets/NearBy_images/crocodile.jpg'
import MGM from './assets/NearBy_images/MGM-dizzee.jpg'
import Cholamandal from './assets/NearBy_images/cholamandal.jpg'
import Elliot from './assets/NearBy_images/elliot.jpg'
import Guindy from './assets/NearBy_images/guindy-national-park.jpg'
import Bird from './assets/NearBy_images/bird.jpg'

import Leela1 from './assets/accomodations_images/leela1.jpg'
import Leela2 from './assets/accomodations_images/leela2.webp'
import SRR1 from './assets/accomodations_images/ssr1.jpg'
import SRR2 from './assets/accomodations_images/ssr2.jpg'
import Ginger1 from './assets/accomodations_images/ginger1.jpg'
import Ginger2 from './assets/accomodations_images/ginger2.webp'
import kalyan1 from './assets/accomodations_images/kalyan1.jpg'
import kalyan2 from './assets/accomodations_images/kalyan2.jpg'
import Southern1 from './assets/accomodations_images/Southern1.jpeg'
import Southern2 from './assets/accomodations_images/Southern2.jpg'
import Novotel1 from './assets/accomodations_images/novotel1.jpg'
import Novotel2 from './assets/accomodations_images/novotel2.jpg'
import Hostel1 from './assets/accomodations_images/hostel1.webp'
import Hostel2 from './assets/accomodations_images/hostel2.webp'
import Ict1 from './assets/accomodations_images/itc1.png'
import Ict2 from './assets/accomodations_images/itc2.png'
import Hayatt1 from './assets/accomodations_images/hyatt1.webp'
import Hayatt2 from './assets/accomodations_images/hyatt2.webp'

import WasmLogo from './assets/WAMSLogo.png'

import Cmi from './assets/sponsors/cmi.png'
import Aps from './assets/sponsors/aps.jpg'
import Raos from './assets/sponsors/raos.png'



export const ImportantDatedata = [
    {
        id : 1,
        title : 'Submission Deadline:',
        date : '31.12.2024',
    
    }, 
    {
        id : 2,
        title : 'Acceptance notifcation:',
        date : '15.03.2025',
    }, 
    {
        id : 3,
        title : 'Camera Ready submission deadline:',
        date : '10.04.2025',
    }, 
    {
        id : 4,
        title : 'Early-bird Registration deadline:',
        date : '30.04.2025',
    }, 
]
export const AnnouncementData = [
    {
        id : 0,
        announce : 'WAMS 2025 shall be hosted by IIITDM Kancheepuram.',
        date : '03 Mar 2024',
    },
]

export const CommitteeData = [
    {
        id : 0,
        designation : 'Patrons',
        details : [
            {
                id1 : 0,
                image : Kartikeyan,
                name : 'Prof. M.V.Kartikeyan',
                represent : 'IIITDM Kancheepuram',
            },
        ],
    },
    {
        id : 1,
        designation : 'Co-Patrons',
        details : [
            {
                id1 : 0,
                image : Sudhakar,
                name : 'Dr. Sudhakar Rao',
                represent : 'Raos Consultants LLC Los Angeles, USA',
            },
        ],
    },
    {
        id : 2,
        designation : 'General Chair',
        details : [
            {
                id1 : 0,
                image : Selvaraj,
                name : 'Prof. M.D.Selvaraj',
                represent : 'IIITDM Kancheepuram',
            },
        ],
    },
    {
        id : 3,
        designation : 'General Co-Chair',
        details : [
            {
                id1 : 0,
                image : Chitti,
                name : 'Dr. B.Chitti Babu',
                represent : 'IIITDM Kancheepuram',
            },
        ],
    },
    {
        id : 4,
        designation : 'Technical Program Committee Chair',
        details : [
            {
                id1 : 0,
                image : PremKumar,
                name : 'Dr. K.Premkumar',
                represent : 'IIITDM Kancheepuram',
            },
        ],
    },
    {
        id : 5,
        designation : 'Organizing Chair',
        details : [
            {
                id1 : 0,
                image : Srijith,
                name : 'Dr. Srijith K.',
                represent : 'IIITDM Kancheepuram',
            },
        ],
    },
    {
        id : 6,
        designation : 'Advisory Committee',
        details : [
            {
                id1 : 0,
                image : Nacer,
                name : ' Dr. Nacer Chahat',
                represent : 'NASA/JPL, USA',
            },
            {
                id1 : 1,
                image : Kahng,
                name : 'Prof. S. Kahng',
                represent : 'Incheon National University, South Korea',
            },
            {
                id1 : 2,
                image : Desmond,
                name : 'Prof. Desmond Sim',
                represent : 'Feng Chia University, Taiwan',
            },
            {
                id1 : 3,
                image : Christopher,
                name : 'Prof. Chistophe Fumeux',
                represent : 'The University of Adelaide , Australia',
            },
            {
                id1 : 4,
                image : Paolo,
                name : 'Dr. Paolo Noschese',
                represent : 'Thales-Alenia Space, Italy',
            },
            {
                id1 : 5,
                image : Sandeep,
                name : 'Dr. Sandeep Chaturvedi',
                represent : 'Gallium Arsenide Enabling Technology Centre (GAETEC), Hyderabad',
            },
            {
                id1 : 6,
                image : Rao,
                name : 'Mr. C.S. Rao',
                represent : 'CEO, QuadGen Wireless Solutions, Bangalore',
            },
            {
                id1 : 7,
                image : Jiang,
                name : 'Dr. Jiang Zhu',
                represent : 'Meta, USA',
            },
            {
                id1 : 8,
                image : Maryam,
                name : 'Dr. Maryam Rofougaran',
                represent : ' CEO, Movandi, USA',
            },
            {
                id1 : 9,
                image : Reddy,
                name : 'Dr. C.J. Reddy',
                represent : 'Vice President, Altair, USA',
            },
            {
                id1 : 10,
                image : Wu,
                name : 'Prof. Ke Wu',
                represent : 'Ecole Polytechnique, Montreal, Canada',
            },
            {
                id1 : 11,
                image : Rajeev,
                name : 'Mr. Rajeev Jyoti',
                represent : 'Director, In-Space, India',
            },
            {
                id1 : 12,
                image : Clency,
                name : 'Dr. Clency Lee-Yow',
                represent : 'CTO, CMi/Vitesse, USA',
            },
            {
                id1 : 13,
                image : Lotfollah,
                name : 'Prof. Lot Shafai',
                represent : 'University of Manitoba, Canada',
            },
            {
                id1 : 14,
                image : Levent,
                name : 'Prof. Levent Sevgi',
                represent : 'Istanbul Okan University, Turkey',
            },
            {
                id1 : 15,
                image : Rodney,
                name : 'Dr. Rodney Waterhouse',
                represent : 'USA',
            },
            {
                id1 : 16,
                image : Poddar,
                name : 'Dr. A. K. Poddar',
                represent : 'Synergy Microwave Corp, USA',
            },
            {
                id1 : 17,
                image : Taimoor,
                name : 'Prof. Taimoor Khan',
                represent : 'NIT Silchar',
            },
        ],
    },
    {
        id : 7,
        designation : 'Wireless Track Chair',
        details : [
            {
                id1 : 0,
                image : Neelakandan,
                name : 'Dr. Neelakandan Rajamohan',
                represent : 'IIT Goa',
            },
            {
                id1 : 1,
                image : Rahul,
                name : 'Dr. Rahul H Meshram',
                represent : 'IIT Madras',
            },
        ],
    },
    {
        id : 8,
        designation : 'Antenna Track Chair',
        details : [
            {
                id1 : 0,
                image : Malik,
                name : 'Dr. Jagannath Malik',
                represent : 'IIT Patna',
            },
            {
                id1 : 1,
                image : Yuvraj,
                name : 'Dr. Yuvaraj S.',
                represent : 'NIT Andhra Pradesh',
            },
            {
                id1 : 2,
                image : Somak,
                name : 'Prof. Somak Bhattacharya',
                represent : 'IIT Varanasi',
            },
        ],
    },
    {
        id : 9,
        designation : 'Microwave Track Chair',
        details : [
            {
                id1 : 0,
                image : Abhishek,
                name : 'Dr. Abhishek Kumar Jha',
                represent : 'IIT Tirupati',
            },
            {
                id1 : 1,
                image : Gowrish,
                name : 'Dr. Gowrish B.',
                represent : 'IIT Roorkee',
            },
        ],
    },
    {
        id : 10,
        designation : 'Young Professionals/Student Activity/Tutorials Committee',
        details : [
            {
                id1 : 0,
                chair : "(Chair)",
                image : Sreenath,
                name : 'Dr. T. Sreenath Reddy',
                represent : 'IIITDM Kancheepuram',
            },
            {
                id1 : 1,
                image : Poddar,
                name : 'Dr. A. K. Poddar',
                represent : 'Synergy Microwave Corp, USA',
            },
            {
                id1 : 2,
                image : Pradhan,
                name : 'Dr. N. C. Pradhan',
                represent : 'Reykjavik University, Iceland',
            },
            {
                id1 : 3,
                image : Bharathi,
                name : 'Dr. A. Bharathi',
                represent : 'University College of Engineering, Osmania University, Hyderabad',
            },
            {
                id1 : 4,
                image : Gaurangi,
                name : 'Dr. Gaurangi Gupta',
                represent : 'NASA/Jet Propulsion Laboratory (JPL), California Institute of Technology, USA',
            },
            {
                id1 : 5,
                image : Avinash,
                name : 'Mr. Avinash Choudhary',
                represent : 'IEEE SBC Chair of IIITDM',
            },
        ],
    },
    {
        id : 11,
        designation : 'Women in Engineering Committee',
        details : [
            {
                id1 : 0,
                image : Binsu,
                name : 'Prof. Binsu J Kailath',
                chair : "(Chair)",
                represent : 'IIITDM Kancheepuram',
            },
            {
                id1 : 1,
                image : Keerthi,
                name : 'Ms. S.Keerthi Priya',
                represent : 'Raman Research Institute, Bengaluru',
            },
            {
                id1 : 2,
                image : Elizabeth,
                name : 'Dr. Elizabeth George',
                represent : 'Digital University of Kerala',
            },
        ],
    },
    {
        id : 12,
        designation : 'Publication Committee',
        details : [
            {
                id1 : 0,
                image : Chitti,
                chair : "(Chair)",
                name : 'Dr. B. Chitti Babu',
                represent : 'IIITDM Kancheepuram',
            },
            {
                id1 : 1,
                image : Srijith,
                name : 'Dr. Srijith K.',
                represent : 'IIITDM Kancheepuram',
            },
        ],
    },
    {
        id : 13,
        designation : 'Finance Chairs',
        details : [
            {
                id1 : 0,
                image : Selvaraj,
                name : 'Prof. M. D. Selvaraj',
                represent : 'IIITDM Kancheepuram',
            },
            {
                id1 : 1,
                image : Sameer,
                name : 'Prof. Sameer Chakravarthy',
                represent : '',
            },
        ],
    },
    {
        id : 14,
        designation : 'Sponsorship & Exhibition Committee',
        details : [
            {
                id1 : 0,
                chair : "(Chair)",
                image : Rohini,
                name : 'Dr. Rohini. P',
                represent : 'IIITDM Kancheepuram',
            },
            {
                id1 : 1,
                image : Uttam,
                name : 'Dr. Pal Uttam Mrinal',
                represent : 'IIITDM Kancheepuram',
            },
            {
                id1 : 2,
                image : Sandeep,
                name : 'Dr. Sandeep Chaturvedi',
                represent : 'GaeTec, Hyderabad',
            },
            {
                id1 : 3,
                image : Shiv,
                name : 'Dr. Shiv Narayan',
                represent : 'NAL Bengaluru',
            },
            {
                id1 : 4,
                image : Rama,
                name : 'Prof. D. Rama Krishna',
                represent : 'Osmania University, Hyderabad',
            },
        ],
    },
    {
        id : 15,
        designation : 'Publicity Committee',
        details : [
            {
                id1 : 0,
                chair : "(Chair)",
                image : Gowri,
                name : 'Dr. Gowri A',
                represent : 'IIITDM Kancheepuram',
            },
            {
                id1 : 1,
                image : Prithiviraj,
                name : 'Prof. V. Prithiviraj',
                represent : '',
            },
            {
                id1 : 2,
                image : Chowdary,
                name : 'Prof. P.S.R. Chowdary',
                represent : '',
            },
        ],
    },
    {
        id : 16,
        designation : 'Keynote & Invited Talks',
        details : [
            {
                id1 : 0,
                chair : "(Chair)",
                image : Dhaval,
                name : 'Prof. Dhaval Pujara',
                represent : 'Director, School of Technology, Pandit Deendayal Energy Technology, Gandhinagar',
            },
            {
                id1 : 1,
                image : Sudhakar,
                name : 'Dr. Sudhakar Rao',
                represent : 'Raos Consultants LLC Los Angeles, USA',
            },
            {
                id1 : 2,
                image : Ravipati,
                name : 'Dr. C. B. Ravipati',
                represent : '',
            },
        ],
    },
    {
        id : 16,
        designation : 'Special sessions & Workshops',
        details : [
            {
                id1 : 0,
                image : SChristopher,
                name : 'Prof. S. Christopher',
                chair : "(Chair)",
                represent : 'IIT Madras Chair',
            },
            {
                id1 : 1,
                image : Desmond,
                name : 'Prof. Desmond Sim',
                represent : 'Feng Chia University, Taiwan',
            },
            {
                id1 : 2,
                image : Sukomal,
                name : 'Prof. Sukomal Dey',
                represent : '',
            },
            {
                id1 : 3,
                image : Nacer,
                name : 'Dr. Nacer Chahat',
                represent : 'NASA/JPL, USA',
            },
        ],
    },
    {
        id : 17,
        designation : 'Student Team',
        details : [
            {
                id1 : 0,
                image : Sahil,
                name : 'Sahil Raza Ansari',
                represent : 'Web Designer and Developer',
            },
            {
                id1 : 1,
                image : Jency,
                name : 'Jency J',
                represent : 'Content creator',
            },
          
        ],
    },
]

export const NearbyData = [
    
  {
    "id": 1,
    "image": kancheepuram,  
    "name": "Kancheepuram",
    "description": 'A temple town known as the "City of Thousand Temples" and "Silk City". It is famous for its Kanchipuram silk sarees and temples like the Varadaraja Perumal Temple, Kailasanathar Temple, and Ekambareswarar Temple.',
    "time": "Varies depending on the temple",
    "fee": '"Varies depending on the temple","Generally around INR 20-50"',
    "location": "About 75 km from Chennai Central"
  },
  {
    "id": 2,
    "image": Mahabalipuram,  
    "name": "Mahabalipuram",
    "description": "A UNESCO World Heritage Site featuring ancient rock-cut temples, monolithic sculptures, and bas-reliefs. The Shore Temple, Arjuna's Penance, and the Panch Rathas are some of the notable attractions.",
    "time": "Open all day",
    "fee": '"Indians": "INR 40", "Foreigners": "INR 600"',
    "location": "About 58 km from Chennai Central"
  },
  {
    "id": 3,
    "image" : Marina,
    "name": "Marina Beach",
    "description": "India's largest beach with up palm trees, offering a classic sunrise view and entertaining activities like horse rides, beach games, and kite flying.",
    "time": "Open all day",
    "fee": "Free",
    "location": "Within the city of Chennai"
    
  },
  {
    "id": 4,
    "image" : Dakshina,
    "name": "Dakshina Chitra",
    "description": "A \"living-history museum\" showcasing South Indian culture through replicas of traditional houses, showcasing art, folk performances, crafts, and architecture. Regular classical dance and music concerts are also held.",
    "time": "10 am to 6 pm (except Tuesday)",
    "fee": "Indian Adults: INR 175, Children (5-12 yrs):INR 6, Children (13-18 yrs):INR 100, Foreign Visitors: INR 350",
    "location": "SH 49, Muttukadu, Tamil Nadu"
  },
  {
    "id": 5,
    "image": Santhome,  
    "name": "Santhome Church",
    "description": "A sparkling white church built on the tomb of St. Thomas. Features stained glass windows, a sitting statue of St. Thomas, a museum, a library, shops selling candles and flowers, and a high cross with a sloping red roof.",
    "time": "6 am to 8 pm",
    "fee": "Free",
    "location": "38, Santhome High Road, Chennai, Tamil Nadu 600004, 17.5 km from Chennai Airport"
  },
  {
    "id": 6,
    "image": Birla,  
    "name": "Birla Planetarium",
    "description": "A planetarium offering audio-visual programs on astronomy, the Solar System, the cycle of stars, sky and seasons, comets, and man on the moon. Also includes a Science Park, Traffic Park, and Science on Wheels.",
    "time": "10 am to 5:45 pm",
    "fee": "Adults:INR 40, Children: INR 25",
    "location": "Gandhi Mandapam Road in Kotturpuram, Chennai, 12.3 km from Chennai Airport"
  },
  {
    "id": 7,
    "image": Valluvar,  
    "name": "Valluvar Kottam",
    "description": "A monument dedicated to the Tamil saint, philosopher, and writer Tiruvalluvar. The monument features 3000 stone blocks in the shape of a chariot with the 133 chapters of Tiruvalluvar's work Thirukkural inscribed on it. There is an auditorium inside, an exhibition hall for handicrafts, and plants planted by Mother Teresa.",
    "time": "Every day 8:30 am- 5:30 pm",
    "fee": "Indians: INR 3.00,Foreigners: INR 50",
    "location": "Gandhi Mandapam Road, Chennai, Tamil Nadu 600030"
  },
  {
    "id": 8,
    "image": Covelong, 
    "name": "Covelong Beach (also known as Kovalam Beach)",
    "description": "A beach known for its serene beauty, watersports activities like windsurfing and swimming, and traditional fishing practices. Also popular for beach camping with bonfires.",
    "time": "Open all day",
    "fee": "Free",
    "location": "40 km from Chennai"
  },
  {
    "id": 9,
    "image": AnnaZoo,  
    "name": "Arignar Anna Zoological Park",
    "description": "A zoological park housing over 500 species of wild animals, including endangered ones. Features sections for lions, deer, elephants, nocturnal animals, an aquarium, butterfly house, reptile house, and amphibian house.",
    "time": "9 am to 5 pm (except Tuesdays)",
    "fee": "Adults: INR 60, Children (3-12 years): INR 30",
    "location": "Vandalur, Chennai, Tamil Nadu, about 31 km from Chennai Central and 15 km from Chennai Airport"
  },
  {
    "id": 10,
    "image": VgpKingdom,  
    "name": "VGP Universal Kingdom",
    "description": "An amusement park offering a variety of rides, water rides, and attractions for all ages. It includes a water park, a dry park, and a haunted house.",
    "time": "10:30 am to 7 pm (weekdays), 10:30 am to 8 pm (weekends)",  
    "fee": 'Weekdays": "INR 500-750 (depending on package)","Weekends": "INR 600-850 (depending on package)"',
    "location": "East Coast Road, Injambakkam, Chennai, Tamil Nadu, about 40 km from Chennai Central"
  },
  {
    "id": 11,
    "image":Crocodile,  
    "name": "Crocodile Bank",
    "description": "A herpetological park showcasing a variety of crocodile and alligator species, including the critically endangered gharial. Also features turtles, snakes, lizards, and a museum dedicated to reptiles.",
    "time": "8:30 am to 5:30 pm",
    "fee": '"Adults": "INR 180","Children (5-12 years)": "INR 90","Students (with ID)": "INR 120"',
    "location": "East Coast Road, Mamallapuram, Tamil Nadu, about 44 km from Chennai Central"
  },
  {
    "id": 12,
    "image": MGM,  
    "name": "MGM Dizzee World",
    "description": "An amusement and water park offering a variety of rides, water slides, and attractions for all ages. It includes a roller coaster, wave pool, and go-karting track.",
    "time": "10:30 am to 7 pm",
    "fee": '"Weekdays": "INR 499 (adults), INR 399 (children)","Weekends": "INR 599 (adults), INR 499 (children)"',
    "location": "ECR, Kelambakkam, Chennai, Tamil Nadu, about 32 km from Chennai Central"
  },
  {
    "id": 13,
    "image": Cholamandal,  
    "name": "Cholamandal Artist Village",
    "description": "A community of artist studios, galleries, and workshops showcasing traditional South Indian art forms like bronze casting, stone carving, and painting. Visitors can interact with artists and witness live demonstrations.",
    "time": "10:30 am to 6:30 pm",
    "fee": "Free (donations accepted)",
    "location": "East Coast Road, Injambakkam, Chennai, Tamil Nadu, about 35 km from Chennai Central"
  },
  {
    "id": 14,
    "image" : Elliot,
    "name": "Elliot's Beach (Besant Nagar Beach)",
    "description": "A less crowded beach with serene beauty and clean brown sands. Attractions include the Schmidt Memorial and the famed AshtaLakshmi Temple and Velankanni Church nearby.",
    "time": "Open all day",
    "fee": "Free",
    "location": "South of Chennai, 10-15 km from Chennai city center"
  },
  {
    "id": 15,
    "image": Guindy,  
    "name": "Guindy National Park and Snake Park",
    "description": "The only national park in the middle of a city, featuring a variety of birds, animals, and reptiles like blackbucks, spotted deer, jackals, tortoises, cobras, pythons, partridges, quails, flycatchers, and buzzards. The snake park is home to a collection of snakes and other reptiles.",
    "time": "9 am to 5:30 pm",
    "fee": "Adults: INR 20, Children: INR 5",
    "location": "Guindy, Chennai, 12 km from Chennai Airport"
  },
  {
    "id": 16,
    "image": Bird,  
    "name": "Vedanthangal Bird Sanctuary",
    "description": "A bird sanctuary home to a variety of migratory birds, including pelicans, cormorants, and flamingos. It is a popular spot for bird watching and photography.",
    "time": "6:30 am to 6 pm",
    "fee": '"Adults": "INR 25", "Children": "INR 10"',
    "location": "About 75 km from Chennai Central"
  }
]
export const StayData = [
   
    {
      "id": 1,
      "image1": Leela1,  
      "image2": Leela2,  
      "name": "The Leela Palace Chennai",
      "description": 'MRC Nagar, R.A Puram, Chennai City Center, Chennai - 600028 ',
      "distance": "39 Km",
      "link" : "https://www.theleela.com/the-leela-palace-chennai"
    },
    {
      "id": 2,
      "image1": Ict1,  
      "image2": Ict2,  
      "name": "Hotel ITC Grand Chola, Chennai",
      "description": 'No. 63, Mount Road, Guindy,Chennai, Tamil Nadu 600032',
      "distance": "45 Km",
      "link" : "https://www.itchotels.com/in/en/itcgrandchola-chennai"
    },
    {
      "id": 3,
      "image1": Hayatt1,  
      "image2": Hayatt2,  
      "name": "Hotel Hyatt Regency, Chennai",
      "description": '365, Anna Salai, Teynampet, Chennai, Tamil Nadu 600018',
      "distance": "50 Km",
      "link" : "https://www.hyatt.com/hyatt-regency/en-US/chenn-hyatt-regency-chennai"
    },
   
    {
      "id": 4,
      "image1": SRR1,  
      "image2": SRR2,  
      "name": "Hotel SRR Grand",
      "description": "133 GST Road, Otteri, Vandalur, Chennai.",
      "distance": "15 Km",
      "link" : "https://hotelsrrgrand.com/"
    },
    {
      "id": 5,
      "image1" : Ginger1,
      "image2" : Ginger2,
      "name": "Hotel Ginger",
      "description": "No 10, OMR Toll Plaza, Navalur, Tamil Nadu, Chennai, Tamil Nadu - 600130",
      "distance": "18 Km",
      "link" : "https://www.gingerhotels.com/ginger-chennai-omr"
    },
    {
      "id": 6,
      "image1" : kalyan1,
      "image2" : kalyan2,
      "name": "Hotel Kalyan Grand",
      "description": "#247, GST Road, Vandalur, Chennai – 600048",
      "distance": "9.6 Km",
      "link" : "https://kalyangrand.com/"
    },
    {
      "id": 7,
      "image1" : Southern1,
      "image2" : Southern2,
      "name": "Hotel Southern Residency",
      "description": "130, Kelambakam-Kovalam Road, Kittu Nagar, Kelambakkam, Chennai - 603 103.",
      "distance": "13 Km",
      "link" : "https://www.southernresidency.com/"
    },
    {
      "id": 8,
      "image1" : Novotel1,
      "image2" : Novotel2,
      "name": "Novotel Chennai OMR",
      "description": "It Expressway, opp. Elcot Sez, Sholinganallur, Chennai, Tamil Nadu 600119",
      "distance": "17 Km",
      "link" : "https://all.accor.com/hotel/6714/index.en.shtml"
    },
    {
      "id": 9,
      "image1" : Hostel1,
      "image2" : Hostel2,
      "name": "IIITDM Kancheepuram Hostels",
      "description": "Student participants (both girls and boys) may approach the organizers to avail FREE accommodations in the Hostels",
      "link" : "https://www.iiitdm.ac.in/hostel"
    },
]

export const AwardData = [
    {
        "id": 1,
        "awardtitle": "Dr. Sudhakar Rao Award for Best Paper of WAMS 2025",
        "criteria": "Selection shall be based on originality and potential application to an engineering product that is related to any of the three tracks of the symposium. The award shall be given to students or young professionals of age 40 years or below and the awardee shall be the first author of the accepted paper for oral presentation. The paper must be presented during the symposium to qualify for the award.",
        "award": "The awardee, first author of the selected paper, will receive a cash prize of INR 30000, a plaque, a certificate and all the registered co-authors shall receive plaques and a certificates.",
        "image": Sudhakar,  
        "name": "Dr. Sudhakar Rao",
        "details": 'Raos Consultants LLC Los Angeles, USA',
    },
    {
        "id": 2,
        "awardtitle": "Prof. Satish K. Sharma Best Paper Award for Female Doctoral Student ",
        "criteria": "The awardee shall be a female doctoral student and the first author of a paper presented orally during the symposium in the antenna and microwave track.",
        "award": "The awardee, who must be the first author of the paper and registered for the conference, will receive a cash prize of INR 15000, a plaque, and a certificate. All the registered co-authors will also receive plaques and a certificates.",
        "image": Satish,  
        "name": "Prof. Satish K. Sharma",
        "details": 'San Diego State University',
    },
    {
        "id": 3,
        "awardtitle": "Dr. Dhaval Pujara Award for Excellence in Academics and Overall Achievement",
        "criteria": "The award will be presented to a current undergraduate student who has demonstrated outstanding performance in academics, as well as exemplary participation in extra and co-curricular activities over the past year(s). Additionally, the recipient must have a paper accepted for presentation at WAMS 2025 and be registered to attend the conference.",
        "award": "The selected awardee will receive a cash prize of INR 5000, along with a certificate and a plaque recognizing awardee's achievements.",
        "image": Dhaval,  
        "name": "Dr. Dhaval Pujara",
        "details": 'Director, School of Technology, Pandit Deendayal Energy Technology, Gandhinagar',
    },
    {
        "id": 4,
        "awardtitle": "Dr. Desmond Sim Award for Best Paper in Antenna Design for Mobile Devices",
        "criteria": "The awardee shall be either an undergraduate/ post-graduate/research student and be the first author of an orally presented paper related to all tracks of the symposium.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 20000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": Desmond,  
        "name": "Prof. Desmond Sim",
        "details": 'Feng Chia University, Taiwan',
    },
    {
        "id": 5,
        "awardtitle": "Vitesse/CMi Award for Antenna Application Paper​",
        "givenBy" : "This award is sponsored by Dr. Clency Lee-Yow, CTO, Vitesse/Custom Microwave Inc, Longmont, Colorado, USA.",
        "criteria": "Selection shall be based on the antenna hardware application to satellite or ground communication systems. The paper must be presented orally during the conference and the awardee shall be the first author of the paper.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 20000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": Clency,  
        "name": "Dr. Clency Lee-Yow",
        "details": 'CTO, CMi/Vitesse, USA',
    },
    {
        "id": 6,
        "awardtitle": "WAMS 2024 Professional Excellence Award for Women in Engineering​",
        "givenBy" : "This award is sponsored by the WAMS Society to recognize women engineers that excelled in engineering in the areas of Wireless, Antenna, & Microwaves.",
        "criteria": "Professional women engineers who excelled in their careers and are known as “role models” to students and young professionals are considered for this award.",
        "award": "The awardee shall receive a cash prize of INR 20000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 7,
        "awardtitle": "Dr. C.J. Reddy Best Paper Award for Female Young Professionals Criteria",
        "criteria": "The awardee shall be 40 years of age or younger and shall be the first author of a paper presented orally during the symposium in the antenna track.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 15000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": Reddy,  
        "name": "Dr. C.J. Reddy",
        "details": 'Vice President, Altair, USA',
    },
    {
        "id": 8,
        "awardtitle": "Dr. C.J. Reddy Best Paper Award for Male Young Professionals Criteria",
        "criteria": "The awardee shall be 40 years of age or younger and shall be the first author of a paper presented orally during the symposium in the antenna track.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 15000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": Reddy,  
        "name": "Dr. C.J. Reddy",
        "details": 'Vice President, Altair, USA',
    },
    {
        "id": 9,
        "awardtitle": "Dr. Nacer Chahat Award for Best Antenna Paper from Industry Professional Criteria​",
        "criteria": "The awardee shall be an industry professional and be the first author of an accepted paper for oral presentation and having practical significance.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 10000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": Nacer,  
        "name": "Dr. Nacer Chahat",
        "details": 'NASA/JPL, USA',
    },
    {
        "id": 10,
        "awardtitle": "Dr. Jonathan Sauder Award for Best Collaborative RF/Mechanical Antenna Design Paper",
        "criteria": "This award will be given to a paper best highlighting collaborative design efforts between RF and Mechanical/Structural/or Manufacturing Engineer . The paper must be presented orally during the conference, and have at least two authors, one of whom is the RF lead and the other is the mechanical lead. The awardees will be the lead RF engineer and the lead mechanical engineer on the paper (only one is required to be present, but both will receive a prize and award).",
        "award": "The awardees shall be the first two authors of the paper and registered participants of the conference, and will receive a cash prize of INR 20000 (shared between two), a plaque, a certificate and all other registered co-authors shall receive a plaque and a certificate.",
        "image": Jonathan,  
        "name": "Dr. Jonathan Sauder",
        "details": 'NASA /Jet Propulsion Laboratory',
    },
    {
        "id": 11,
        "awardtitle": "WAMS 2025 Career Excellence Award in Engineering",
        "criteria": "The award is presented to an outstanding professional engineer who excelled in their career with demonstrated contributions.",
        "award": "The awardee receives a cash prize of INR 20000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 12,
        "awardtitle": "Dr. Sudhakar Rao Award for Best Outgoing All-Round Female Student from IIITDM, Kancheepuram",
        "criteria": "The award is presented to the Best Female Student from ECE branch for her All Rounder Performance.",
        "award": "The awardee will receive a cash prize of INR 10000, a plaque, and a certificate",
        "image": Sudhakar,  
        "name": "Dr. Sudhakar Rao",
        "details": 'Raos Consultants LLC Los Angeles, USA',
    },
    {
        "id": 13,
        "awardtitle": "Dr. Sudhakar Rao Award for Best Outgoing All-Round Male Student from IIITDM, Kancheepuram",
        "criteria": "The award is presented to the Best Male Student from ECE branch for his All Rounder Performance.",
        "award": "The awardee will receive a cash prize of INR 10000, a plaque, and a certificate",
        "image": Sudhakar,  
        "name": "Dr. Sudhakar Rao",
        "details": 'Raos Consultants LLC Los Angeles, USA',
    },
    {
        "id": 14,
        "awardtitle": "Dr. Zubair Akhter Award for Multi-Disciplinary Collaborative Research Best Paper",
        "givenBy" : "The Dr. Zubair Akhter Award seeks to honor individuals who embody the spirit of collaboration across diverse fields of expertise. This prestigious award recognizes those who leverage their unique perspectives to craft innovative solutions for complex societal challenges. By fostering groundbreaking ideas, enhancing resource sharing, and strengthening the interdepartmental research ecosystem, this award aims to inspire future generations of researchers to tackle pressing global issues.",
        "criteria": "Eligible candidates for this esteemed award include undergraduate, postgraduate, and research students who are the first authors of orally presented papers across all symposium tracks. The selected paper shall demonstrate collaborative research efforts involving two or more institutions, showcasing the power of interdisciplinary teamwork in advancing knowledge and innovation.",
        "award": "The recipient of the Dr. Zubair Akhter Award shall be the first author of the outstanding paper and must be registered for the conference. The award package includes a cash prize of INR 20000, a beautifully crafted plaque, and a certificate of recognition. Additionally, all registered co-authors will receive a plaque and a certificate, celebrating their contributions to this collaborative endeavor. This award not only recognizes excellence in research but also champions the collaborative spirit that is essential for addressing the multifaceted challenges of our time.",
        "image": Zubair,  
        "name": "Dr. Zubair Akhter",
        "details": 'Senior Antenna Engineer(High Power EM Division) Directed Energy Research Center, Technology Innovation Institute(TII), Abu Dhabi, UAE',
    },
    {
        "id": 15,
        "awardtitle": "Late Dr. Rakesh Mohan Jha Award for Best Student Paper in Antenna Track.​",
        "givenBy" : "This award is instituted by Dr. Balamati Choudhury, CSIR-NAL, in memory of her mentor Late Dr. Rakesh Mohan Jha.",
        "criteria": "The awardee (first author) should be a graduate or post-graduate and the paper should be on antenna selected for oral presentation.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 10000, a plaque, a certificate and all the registered co- authors shall receive a plaque and a certificate.",
        "image": Balamati,  
        "name": "Dr. Balamati Choudhury",
        "details": 'Scientist at Centre of Electromagnetics of CSIR-National Aerospace Laboratories, Bangalore, India',
    },
    {
        "id": 16,
        "awardtitle": "WAMS Society Award for Best Student Paper Award in All Tracks​",
        "givenBy" : "This award is sponsored by WAMS Society.",
        "criteria": "The awardee shall be a UG, PG or Ph D student and shall be the first author of a paper (any of the three tracks) submitted and orally presented in WAMS 2025.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 10000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 17,
        "awardtitle": "WAMS 2025 Best Paper in Wireless Track​​",
        "criteria": "The award is presented to the best paper in wireless track.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 10000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 18,
        "awardtitle": "WAMS 2025 Best Overall Paper of all Tracks​​​",
        "criteria": "The award is presented to the best overall paper of all tracks.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 15000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 19,
        "awardtitle": "WAMS 2025 Best Paper in Microwave Track​​​​",
        "criteria": "The award is presented to the best paper in microwave track.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 10000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 20,
        "awardtitle": "WAMS 2025 Best Paper in Antenna Track​​​​",
        "criteria": "The award is presented to the best paper in antenna track.",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 10000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 21,
        "awardtitle": "WAMS 2025 Best Poster Paper (First Place​​​​)",
        "criteria": "The award is presented to the best poster paper (first place).",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 15000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 22,
        "awardtitle": "WAMS 2025 Best Poster Paper (Second Place​​​​)",
        "criteria": "The award is presented to the best poster paper (second place).",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 10000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 23,
        "awardtitle": "WAMS 2025 Best Poster Paper (Third Place​​​​)",
        "criteria": "The award is presented to the best poster paper (third place).",
        "award": "The awardee shall be the first author of the paper and registered to the conference, will receive a cash prize of INR 5000, a plaque, a certificate and all the registered co-authors shall receive a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 24,
        "awardtitle": "Best Student Paper Award Under Ph.D. Category (First Place)",
        "criteria": "The award is presented to the best student paper award under Ph.D category (first place).",
        "award": "The awardee receives cash prize of INR 15000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 25,
        "awardtitle": "Best Student Paper Award Under Ph.D. Category (Second Place)",
        "criteria": "The award is presented to the best student paper award under Ph.D category (second place).",
        "award": "The awardee receives cash prize of INR 10000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 26,
        "awardtitle": "Best Student Paper Award Under Masters Category (First Place)",
        "criteria": "The award is presented to the best student paper award under Masters category (first place).",
        "award": "The awardee receives cash prize of INR 15000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 27,
        "awardtitle": "Best Student Paper Award Under Masters Category (Second Place)",
        "criteria": "The award is presented to the best student paper award under Masters category (second place).",
        "award": "The awardee receives cash prize of INR 10000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 28,
        "awardtitle": "Best Student Paper Award Under Bachelors Category (First Place)",
        "criteria": "The award is presented to the best student paper award under Bachelors category (first place).",
        "award": "The awardee receives cash prize of INR 15000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 29,
        "awardtitle": "Best Student Paper Award Under Bachelors Category (Second Place)",
        "criteria": "The award is presented to the best student paper award under Bachelors category (second place).",
        "award": "The awardee receives cash prize of INR 10000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 30,
        "awardtitle": "WAMS 2025 Best Woman Research Scholar Award (First Place)",
        "criteria": "The award is presented to the best woman research scholar (first place).",
        "award": "The awardee awardee receives cash prize of INR 15000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 31,
        "awardtitle": "WAMS 2025 Best Woman Research Scholar Award (Second Place)",
        "criteria": "The award is presented to the best woman research scholar (second place).",
        "award": "The awardee awardee receives cash prize of INR 10000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 32,
        "awardtitle": "Best WIE Paper Presentation by an Industry Professional (First Place)",
        "criteria": "The award is presented to the best WIE industry professional (first place).",
        "award": "The awardee receives cash prize of INR 8000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 33,
        "awardtitle": "Best WIE Paper Presentation by an Industry Professional (Second Place)",
        "criteria": "The award is presented to the best WIE industry professional (second place).",
        "award": "The awardee receives cash prize of INR 5000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 34,
        "awardtitle": "Young Professionals Excellence Award",
        "criteria": "The award is presented to a young professional under the age of 40 who demonstrates a promising career path in the areas of Wireless, Antennas and Microwave Engineering.",
        "award": "The awardee receives cash prize of INR 15000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 35,
        "awardtitle": "Outstanding Male Student Volunteer Award​",
        "criteria": "The award is presented to the outstanding male student volunteer.",
        "award": "The awardee receives cash prize of INR 10000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 36,
        "awardtitle": "Outstanding Female Student Volunteer Award​",
        "criteria": "The award is presented to the outstanding female student volunteer.",
        "award": "The awardee receives cash prize of INR 10000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 37,
        "awardtitle": "Outstanding Organizer of WAMS 2025 Award​",
        "criteria": "The award is presented to the outstanding organizer of WAMS 2025.",
        "award": "The awardee receives cash prize of INR 20000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 38,
        "awardtitle": "Outstanding Contributor Award to Technical Program of WAMS 2025​",
        "criteria": "The award is presented to the outstanding contributor to technical program of WAMS 2025",
        "award": "The awardee receives cash prize of INR 20000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 39,
        "awardtitle": "WAMS 2025 Career Excellence Award in Engineering",
        "criteria": "The award is presented to the professional engineer who excelled in his/her career with demonstrated outstanding contributions to the society.",
        "award": "The awardee receives a cash prize of INR 25000, a plaque, and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 40,
        "awardtitle": "Outstanding Contributor to WAMS Society",
        "givenBy" : "This award is sponsored by WAMS Society.",
        "criteria": "The award is presented to an outstanding individual that contributed to the formation and development of WAMS Society.",
        "award": "The selected awardee receives a cash prize of INR 20000, a plaque, and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
    {
        "id": 41,
        "awardtitle": "Young Professional Service Award",
        "criteria": "The award is presented to Young Professional that made significant contributions to WAMS Society and WAMS 2025",
        "award": "The awardee receives a cash prize of INR 15000, a plaque and a certificate.",
        "image": WasmLogo,  
        "name": "Wams Society",
        "details": '',
    },
] 

export const sponsors = [
    {
      id: 1,
      name: "Custom Microwave inc. / Vitesse Systems",
      title: "Diamond Sponsor",
      url: "https://custommicrowave.com/",
      logo: Cmi

    },
    {
      id: 2,
      name: "IEEE Antenna and Propagation Society (APS)",
      title: "Platinum Sponsor",
      url: "https://ieeeaps.org/",
      logo: Aps

    },
    {
      id: 3,
      name: "RaoS Consultants LLC",
      title: "Bronze Sponsor",
      url: "https://raosconsultants.com/",
      logo: Raos
    },
  ];
  