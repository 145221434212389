import React from 'react';
import styled from 'styled-components';
import { sponsors } from '../data';
import { Link } from 'react-router-dom';

const SponsorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const SponsorLogo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 10px;
`;

const SponsorName = styled.h3`
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 5px 0;
  color : #214B8C;
`;

const SponsorTitle = styled.p`
  font-size: 20px;
  text-align: center;
  margin: 5px 0;
  color : #214B8C;
`;

const SponsorButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size : 18px;
  &:hover {
    background-color: #214B8C;
  }
`;

const SponsorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SponsorsTitle = styled.h2`
  font-size : 40px;
  color : #214B8C;
  padding-bottom : 30px;
`;

const SponsorCard = () => {
  return (
    <SponsorsWrapper>
      <SponsorsTitle>Sponsors for WAMS 2025</SponsorsTitle>
      {sponsors.map(sponsor => (
        <SponsorContainer key={sponsor.id}>
          <SponsorLogo src={sponsor.logo} alt={sponsor.name} />
          <SponsorName>{sponsor.name}</SponsorName>
          <SponsorTitle>{sponsor.title}</SponsorTitle>
          <Link style={{textDecoration : "none", zIndex : "2"}} to = {sponsor.url} target='_blank'><SponsorButton>Know More</SponsorButton></Link> 
        </SponsorContainer>
      ))}
    </SponsorsWrapper>
  );
};

export default SponsorCard;
