import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Pages/Home';
import { createGlobalStyle } from 'styled-components';
import TempWorking from './Pages/TempWorking';
import ContactUs from './Pages/ContactUs';
import Committee from './Pages/Committee';
import Attraction from './Pages/Attraction';
import Travel from './Pages/Travel';
import Accomodation from './Pages/Accomodation';
import SubmitPaper from './Pages/SubmitPaper';
import Awards from './Pages/Awards';
import SponsorPage from './Pages/SponsorPage';
import SponsorShip from './Pages/SponsorShip';
import Promotions from './Pages/Promotions';
import PaperSubmit from './Pages/Paper_Submit';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', san-serif;
    margin: 0;
    padding: 0;
  }
`;

function App() {
  return (<>
    <GlobalStyle/>
    <Router>
      <Routes>
        <Route exact path='/' element = {<Home/>}></Route>
        <Route exact path='/home' element = {<Home/>}></Route>
        <Route exact path='/work-in-progress' element = {<TempWorking/>}></Route>
        <Route exact path='/contact' element = {<ContactUs/>}></Route>
        <Route exact path='/committee' element = {<Committee/>}></Route>
        <Route exact path='/nearby-attractions' element = {<Attraction/>}></Route>
        <Route exact path='/travel' element = {<Travel/>}></Route>
        <Route exact path='/accomodation' element = {<Accomodation/>}></Route>
        <Route exact path='/submit-a-paper' element = {<SubmitPaper/>}></Route>
        <Route exact path='/Paper-Submission' element = {<PaperSubmit/>}></Route>
        <Route exact path='/students-awards' element = {<Awards/>}></Route>
        <Route exact path='/our-sponsor' element = {<SponsorPage/>}></Route>
        <Route exact path='/sponsorship' element = {<SponsorShip/>}></Route>
        <Route exact path='/promotions' element = {<Promotions/>}></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
