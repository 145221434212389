import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { maximobile } from '../responsive';

const TimerContainer = styled.div`
  display: flex;
  justify-content: center;
  ${maximobile({
    flexDirection : "column",
  })}
`;

const TimerBox = styled.div`
  margin: 0 10px;
  background-color : #214B8C;
  border-radius : 15px;
  padding : 20px;
  ${maximobile({
    width : "30px",
    height : "40px",
    margin : "10px 10px",
  })}
`;

const NumberDisplay = styled.div`
  font-size: 24px;
  font-weight: bold;
  color : white;
  text-align : center;
  padding-bottom : 10px;
  ${maximobile({
    fontSize : "18px",
  })}
`;

const Text = styled.span`
  color : white;
  font-weight : bold;
  display : flex;
  justify-content : center;
  ${maximobile({
    fontSize : "10px",
  })}
`

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <TimerContainer>
      <TimerBox>
        <NumberDisplay>{String(timeLeft.days).padStart(2, '0')}</NumberDisplay>
        <Text>Days</Text>
      </TimerBox>
      <TimerBox>
        <NumberDisplay>{String(timeLeft.hours).padStart(2, '0')}</NumberDisplay>
        <Text>Hours</Text>
      </TimerBox>
      <TimerBox>
        <NumberDisplay>{String(timeLeft.minutes).padStart(2, '0')}</NumberDisplay>
        <Text>Minutes</Text>
      </TimerBox>
      <TimerBox>
        <NumberDisplay>{String(timeLeft.seconds).padStart(2, '0')}</NumberDisplay>
        <Text>Seconds</Text>
      </TimerBox>
    </TimerContainer>
  );
};

export default CountdownTimer;
