import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InstituteImage from '../assets/slider/Institute.jpg';
import CountdownTimer from './Timer';
import { maxitab, miniimobile, miniminimobile } from '../responsive';
import axios from 'axios';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  ${maxitab({
    flexDirection: 'column',
  })}
`;

const LeftContainer = styled.div`
  width: 60vw;
  display: flex;
  overflow: hidden;
  z-index: -1;
  position: relative;
  background-color: black;
  flex: 1.7;
  ${maxitab({
    width: '100vw',
  })}
`;

const Wrapper = styled.div`
  display: flex;
  width: 61vw;
`;

const Image = styled.img`
  width: 99vw;
  height: 74.5vh;
  object-fit: cover;
  ${maxitab({
    width: '100vw',
  })}
`;

const Timer = styled.div`
  position: absolute;
  right: 10%;
  bottom: 10%;
`;

const AnnounceTitle = styled.p`
  font-size: 30px;
  color: black;
  margin-bottom: 10px;
`;

const AnnouncementContainer = styled.div`
  padding: 20px;
  flex: 1;
  z-index: 0;
  background-color: #efefef;
  width: 90%;
  max-height: 70vh;
  overflow-y: auto;
  ${maxitab({
    margin: '20px',
    marginBottom: '0px',
    borderRadius: '20px',
    height: 'auto', 
  })}
`;

const AnnounceInfoContainer = styled.div`
  max-height: 60vh; 
  overflow-y: auto;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: #214B8C;
    border-radius: 10px;
  }
`;

const AnnounceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
`;

const Announce = styled.p`
  color: #214B8C;
  font-size: 18px;
  width: 100%;
  margin-bottom: 5px;
  ${miniimobile({
    fontSize: '16px',
  })}
  ${miniminimobile({
    fontSize: '14px',
  })}
`;

const Date = styled.p`
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
  ${miniimobile({
    fontSize: '16px',
  })}
  ${miniminimobile({
    fontSize: '14px',
  })}
`;

const AnnounceDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 15px;
  height: 15px;
  border: 4px solid #214B8C;
  border-radius: 50%;
  outline: none;
  margin-right: 8px;
  &:checked {
    background-color: white;
  }
`;

const Slider = () => {
  const targetDate = '2025-06-05T00:00:00';
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const sheetId = '1UuyA5oVGJ2d10HlMqDXXh4oNlPXckizJUUyOlAU3GdQ';
      const apiKey = 'AIzaSyAyc0Ajf3HYaeKwEu-Dps1VikUkEJNtJIE';
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/Sheet1?key=${apiKey}`;

      try {
        const response = await axios.get(url);
        const rows = response.data.values;
        const data = rows.slice(1).map((row) => ({
          sno: row[0],
          message: row[1],
          date: row[2],
        }));
        setAnnouncements(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <LeftContainer>
        <Wrapper>
          <Image src={InstituteImage} />
        </Wrapper>
        <Timer>
          <CountdownTimer targetDate={targetDate} />
        </Timer>
      </LeftContainer>
      <AnnouncementContainer>
        <AnnounceTitle>| Announcements</AnnounceTitle>
        <AnnounceInfoContainer>
          {announcements.map(item => (
            <AnnounceDetails key={item.sno}>
              <StyledCheckbox />
              <AnnounceInfo>
                <Announce>{item.message}</Announce>
                <Date>{item.date}</Date>
              </AnnounceInfo>
            </AnnounceDetails>
          ))}
        </AnnounceInfoContainer>
      </AnnouncementContainer>
    </Container>
  );
};

export default Slider;
