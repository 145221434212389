import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import PaperSubmission from '../Components/PaperSubmission'

const PaperSubmit = () => {
  return (
    <>
    <Navbar/>
    <PaperSubmission/>
    <Footer/>
    </>
  )
}

export default PaperSubmit