import React, { useState } from 'react';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import { maxitab, minitab } from '../responsive';

const HeadingContainer = styled.div`
  padding: 20px;
`;

const Heading = styled.h2`
  text-align: center;
  font-size: 40px;
  color: #214B8C;
  ${maxitab({
    fontSize: '35px',
  })}
  ${minitab({
    fontSize: '30px',
  })}
`;

const HeadingDescription = styled.p`
  text-align: center;
  font-size: 20px;
  color: #214B8C;
  ${maxitab({
    fontSize: '18px',
  })}
  ${minitab({
    fontSize: '16px',
  })}
`;

const Container = styled.div`
  width: 99vw;
  ${minitab({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })}
`;

const Wrapper = styled.div`
  display: flex;
  height: auto;
  ${minitab({
    flexDirection: 'column',
    alignItems: 'center',
  })}
`;

const Image = styled.img`
  width: 30vw;
  height: 30vw;
  object-fit: cover;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 4px black;
  ${maxitab({
    width: '60vw',
  })}
  ${minitab({
    width: '80vw',
    margin: '10px 0',
  })}
`;

const InfoContainer = styled.div`
  width: 100%;
  margin: 10px;
  height: auto;
  ${minitab({
    textAlign: 'center',
  })}
`;

const Title = styled.h2`
  font-size: 30px;
  color: #214B8C;
  width: 90%;
  ${maxitab({
    fontSize: '25px',
    textAlign: 'center',
    width: '100%',
  })}
`;

const Description = styled.p`
  color: #214B8C;
  width: 90%;
  text-align: justify;
  font-size: 20px;
  ${maxitab({
    fontSize: '16px',
    textAlign: 'start',
    width: '100%',
  })}
  ${minitab({
    textAlign: 'center',
  })}
`;

const Timings = styled.p`
  color: #214B8C;
  text-align: justify;
  width: 90%;
  font-size: 20px;
  ${maxitab({
    fontSize: '16px',
    textAlign: 'center',
    width: '100%',
  })}
`;

const EntryFee = styled.p`
  color: #214B8C;
  text-align: justify;
  width: 90%;
  font-size: 20px;
  ${maxitab({
    fontSize: '16px',
    textAlign: 'center',
    width: '100%',
  })}
`;

const Location = styled.p`
  color: #214B8C;
  text-align: justify;
  width: 90%;
  font-size: 20px;
  ${maxitab({
    fontSize: '16px',
    textAlign: 'center',
    width: '100%',
  })}
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const PageButton = styled.button`
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 5px;
  font-size: 18px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#214B8C' : 'unset')};
  color: ${(props) => (props.active ? 'white' : 'black')};
  ${maxitab({
    fontSize: '16px',
  })}
  ${minitab({
    fontSize: '14px',
  })}
`;

function Products({ products }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const displayedProducts = products.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <HeadingContainer>
        <Heading>Nearby Attractions</Heading>
        <HeadingDescription>Embark on a journey through Tamil Nadu's enchanting landscapes, where majestic temples stand as timeless guardians of tradition, while verdant hills and serene beaches invite you to lose yourself in nature's embrace.</HeadingDescription>
      </HeadingContainer>
      <Container>
        {displayedProducts.map((product) => (
          <Wrapper key={product.idx}>
            <Image src={product.image} alt={product.name} />
            <InfoContainer>
              <Title>{product.name}</Title>
              <Description>{product.description}</Description>
              <Timings><b>Timings: </b>{product.time}</Timings>
              <EntryFee><b>Entry Fee: </b>{product.fee}</EntryFee>
              <Location><b>Location: </b>{product.location}</Location>
            </InfoContainer>
          </Wrapper>
        ))}
      </Container>
      {totalPages > 1 && (
        <PaginationContainer>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
            <Link style={{ textDecoration: 'none', zIndex: '2' }} to="#top" key={pageNumber}>
              <PageButton
                active={currentPage === pageNumber}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </PageButton>
            </Link>
          ))}
        </PaginationContainer>
      )}
    </div>
  );
}

export default Products;
