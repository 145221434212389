import React from 'react'
import styled from 'styled-components'
import { miniimobile } from '../responsive'

const Container = styled.div`
    width : 99vw;
    height : auto;
    display : flex;
    align-items : center;
    flex-direction : column;
    padding : 30px 0px;
`
const Title = styled.h2`
    font-size : 40px;
    color : #214B8C;
    padding-bottom : 30px;
`
const Wrapper = styled.div`
    display : flex;
    ${miniimobile({
        flexDirection : "column",
    })}
`
const ContactDetails = styled.div`
    line-height : 30px;
    padding : 0px 40px;
    box-shadow : 0px 0px 10px 4px #214B8C;
    margin : 20px;
    padding : 20px;
    border-radius : 20px;
`
const Designation = styled.h2`
    font-size : 30px;
    color : #214B8C;
`   
const Name = styled.div`
    font-size : 20px;
    font-weight : bold;
    color : #214B8C;
`
const Number = styled.div`
    font-size : 18px;
    color : #214B8C;
`
const Email = styled.div`
    font-size : 18px;
    color : #214B8C;
`

const Contact = () => {
  return (
    <Container>
        <Title>CONTACT DETAILS</Title>
        <Wrapper>
            <ContactDetails>
                <Designation>General Chair</Designation>
                <Name>Prof. M.D.Selvaraj</Name>
                <Number><b>Contact Number:</b> +91 9941524269</Number>
                <Email><b>Email:</b> selvaraj@iiitdm.ac.in</Email>
            </ContactDetails>
            <ContactDetails>
                <Designation>Organizing Chair</Designation>
                <Name>Dr. Srijith K.</Name>
                <Number><b>Contact Number:</b> +91 9444165638</Number>
                <Email><b>Email:</b> srijith@iiitdm.ac.in</Email>
            </ContactDetails>
        </Wrapper>
    </Container>
  )
}

export default Contact