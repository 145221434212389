import React from 'react'
import styled from 'styled-components'
import { ImportantDatedata } from '../data'
import { miniimobile } from '../responsive'

const ImportantDateContainer = styled.div`
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
`

const ImportantDateInfo = styled.div`
    width: 80%;
    background-color: #214B8C;
    display: flex;
    border-radius: 30px;
    padding: 20px 0px;
    align-items : center;
    flex-direction: column;
    margin-bottom: 50px;
`

const ImportantDateTitle = styled.h3`
    font-size: 40px;
    line-height: 0px;
    color: #efefef;
    text-align : center;
    ${miniimobile({
        fontSize : "30px",
    })}
`

const ImportantDates = styled.ul`
    display: flex;
    flex-direction: column;
    padding : 30px;
`

const ImportantDateItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
`

const Icon = styled.img`
    margin-right: 10px;
    width: 50px;
    height: 50px;
    ${miniimobile({
        width : "40px",
        height : "40px",
    })}
`

const Title = styled.h2`
    margin-right: 30px;
    flex: 3;
    color: white;
    font-weight: bold;
    font-size: 24px;
    ${miniimobile({
        fontSize : "16px",
    })}
`

const Date = styled.p`
    font-size: 18px;
    color: white;
    flex: 1;
    ${miniimobile({
        fontSize : "14px",
    })}
`

const ImportantDate = () => {
    return (
        <ImportantDateContainer id='dates'>
            <ImportantDateInfo>
                <ImportantDateTitle>Important Dates</ImportantDateTitle>
                <ImportantDates>
                    {ImportantDatedata.map(item => (
                        <ImportantDateItem key={item.id}>
                            <Icon src='https://i.ibb.co/wgZ5Hrs/icons8-date-50.png' />
                            <Title>{item.title}</Title>
                            <Date>{item.date}</Date>
                        </ImportantDateItem>
                    ))}
                </ImportantDates>
            </ImportantDateInfo>
        </ImportantDateContainer>
    )
}

export default ImportantDate
