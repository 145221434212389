import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
`;

const Title = styled.h2`
  font-size: 40px;
  color: #214B8C;
  padding-bottom: 30px;
  text-align: center;
`;

const Description = styled.p`
  text-align: center;
  font-size: 20px;
  color: #214B8C;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  
`;

const Box = styled.div`
  background-color: ${props => props.bgColor || '#fff'};
  margin: 30px;
  padding: 20px;
  border-radius: 20px;
`;

const ListTitle = styled.h2`
  font-size: 30px;
  text-align: center;
`;

const Lists = styled.div`
  margin-top: 20px;
`;

const List = styled.p`
  font-size: 20px;
`;

const DownloadLink = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;

const PaperCall = () => {
  return (
    <Container>
      <Description>Please download the Call For Papers here <DownloadLink href="https://jmp.sh/cPIvQmJs" target='_blank'>Click here</DownloadLink></Description>
      <Title>CALL FOR PAPERS</Title>
      <Description>
        We invite original contributions relevant to the theme of the symposium falling into one of the following tracks (but not limited to)
      </Description>
      <Wrapper>
        <Box bgColor="#F8E893">
          <ListTitle>Wireless</ListTitle>
          <Lists>
            <List>●  5G & 6G Communications</List>
            <List>●  ML/AI-based approaches</List>
            <List>●  Quantum Communications & Computing</List>
            <List>●  Next generation networks</List>
            <List>●  RF & Optical communication</List>
            <List>●  Signal Processing for communication</List>
            <List>●  Image Processing applications</List>
          </Lists>
        </Box>
        <Box bgColor="#B1F495">
          <ListTitle>Antenna</ListTitle>
          <Lists>
            <List>●  Microwave and mm-wave Antenna Design</List>
            <List>●  Antenna Arrays</List>
            <List>●  Sub-THz, THz and Optical Antennas</List>
            <List>●  Reflector antennas</List>
            <List>●  Satellite, aircraft & ground communication antennas</List>
            <List>●  Wearable antennas</List>
            <List>●  Feed systems</List>
            <List>●  Computational EM</List>
          </Lists>
        </Box>
        <Box bgColor="#F0B490">
          <ListTitle>Microwave</ListTitle>
          <Lists>
            <List>●  RF and microwave</List>
            <List>●  Circuits and systems</List>
            <List>●  Microwave Devices and Components</List>
            <List>●  Microwave photonics</List>
            <List>●  Metamaterials and metasurfaces</List>
            <List>●  Microwave imaging and Remote sensing</List>
            <List>●  RFID</List>
            <List>●  Biomedical applications</List>
          </Lists>
        </Box>
      </Wrapper>
    </Container>
  );
};

export default PaperCall;
