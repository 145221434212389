import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import CommitteeComp from '../Components/CommitteeComp'

const Committee = () => {
  return (
    <>
    <Navbar/>
    <CommitteeComp/>
    <Footer/>
    </>
  )
}

export default Committee