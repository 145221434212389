import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  padding: 40px;
  background-color: #F5F7FA;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
`;

const SectionWrapper = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 28px;
  text-align: center;
  color: #214B8C;
  font-weight: bold;
  margin-bottom: 20px;
`;

const InstructionText = styled.p`
  font-size: 18px;
  color: #333;
  margin: 15px 0;
  text-align: justify;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 40px;
`;

const ListItem = styled.li`
  font-size: 18px;
  color: #214B8C;
  margin: 10px 0;
`;

const StyledLink = styled.span`
  color: #0066cc;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: #003366;
  }
`;

const Deadline = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #D9534F;
  margin-top: 30px;
  text-align: center;
`;

const PaperSubmission = () => {
  return (
    <Container>
      <SectionWrapper>
        <SectionTitle>Instructions for Preparing Manuscript for Submission</SectionTitle>
        <InstructionText>
          Prospective authors are invited to submit manuscripts (full paper(s)) containing original contribution
          with sufficient detail for review.<b> All submissions must take place through "CMT Paper Submission Portal for WAMS 2025"</b>. The contribution that is submitted should not have been published or submitted elsewhere.
        </InstructionText>

        <InstructionText>
            <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://cmt3.research.microsoft.com/WAMS2025" target='_blank'><StyledLink>Paper submission link</StyledLink></Link></InstructionText>
        <InstructionText><strong>No-show policy:</strong> At least one author must present an accepted paper in-person at the conference. 
          Substitutions for presentations are allowed only after prior approval from TPC chairs.
        </InstructionText>

        <InstructionText><strong>Registration:</strong> Each accepted paper should be covered by full registration.</InstructionText>
        <InstructionText><strong>Please keep the following in mind while preparing your manuscripts.</strong></InstructionText>

        <List>
          <ListItem>Submit your original unpublished (and not have been submitted elsewhere) manuscript as a pdf.</ListItem>
          <ListItem>The manuscript should follow the standard IEEE conference template. It should <strong>not exceed FIVE double-column pages</strong>, with single line spacing, main text font size no smaller than 10 points, and at least 3/4 inch margins (about 18 mm).  Papers exceeding page limits will not be accepted.</ListItem>
          <ListItem>Authors are encouraged to use LaTeX to format their papers, using the style file and following the example given below. Authors using Microsoft Word are encouraged to use standard IEEE conference templates from the following link: IEEE conference templates from the following link: <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://www.ieee.org/conferences/publishing/templates.html" target='_blank'><StyledLink>IEEE conference templates.</StyledLink></Link>
          <br /><strong>Paper templates:</strong><Link style={{textDecoration : "none", zIndex : "2"}} to = "https://jmp.sh/CfQdSVfv" target='_blank'> <StyledLink>WAMS_Template_LaTex.pdf</StyledLink></Link>, <a style={{textDecoration : "none", zIndex : "2"}} href = '/assets/pdfs/WAMS_Template_LaTex' download="WAMS_Tempelate_LaTex.tex"> <StyledLink>WAMS_Template_LaTex.tex</StyledLink></a> <br /><strong>LaTeX style file: </strong> <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://ece.iisc.ac.in/~spcom/2024/img/IEEEtran.cls" target='_blank'> <StyledLink>IEEEtran.cls</StyledLink></Link></ListItem>
          <ListItem>The names of the authors cannot be added or removed at a later stage.</ListItem>
          <ListItem>It should have no content written in the margins. In particular, no header, footer, or page numbers.</ListItem>
          <ListItem>Do not put your own page numbers on the manuscript.</ListItem>
        </List>
      </SectionWrapper>
      <InstructionText><strong>All submissions must take place through the <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://cmt3.research.microsoft.com/WAMS2025" target='_blank'><StyledLink>CMT paper submission portal.</StyledLink></Link></strong></InstructionText>
      <Deadline>The deadline for paper submission is December 31, 2024, 23:59 IST.</Deadline>
    </Container>
  );
};

export default PaperSubmission;