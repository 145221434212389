import React from 'react';
import styled from 'styled-components';

const SponsorshipContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Heading = styled.h2`
  text-align: center;
  color: #0047AB;
  margin-bottom: 20px;
`;

const SubHeading = styled.p`
  text-align: center;
  color: #D10F0F;
  font-weight: bold;
  margin-bottom: 40px;
`;

const Card = styled.div`
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  color: black;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const SponsorShipComp = () => {
  return (
    <SponsorshipContainer>
      <Heading>SPONSORSHIPS</Heading>
      <SubHeading>All sponsors & exhibitors are invited for dinner with Organizing Committee on Banquet day</SubHeading>
      
      <Card bgColor="#d1e7ff">
        <Title>Diamond – 5 lakhs+ GST (18%)</Title>
        <List>
          <ListItem>● Six complimentary delegate passes</ListItem>
          <ListItem>● 25 minutes for presentation</ListItem>
          <ListItem>● Complimentary booth at prime location</ListItem>
          <ListItem>● Hotel accommodation (3 nights)</ListItem>
          <ListItem>● Prominent display of logo on all printed material, backdrop, and website</ListItem>
          <ListItem>● Permission to keep company brochures in registration kits</ListItem>
        </List>
      </Card>

      <Card bgColor="#b3e5fc">
        <Title>Platinum – 4 lakhs+ GST (18%)</Title>
        <List>
          <ListItem>● Five complimentary delegate passes</ListItem>
          <ListItem>● 20 minutes for presentation</ListItem>
          <ListItem>● Complimentary booth at prime location</ListItem>
          <ListItem>● Hotel accommodation (3 nights)</ListItem>
          <ListItem>● Prominent display of logo on all printed material, backdrop, and website</ListItem>
          <ListItem>● Permission to keep company brochures in registration kits</ListItem>
        </List>
      </Card>

      <Card bgColor="#ffe0b2">
        <Title>Gold – 3 lakhs+GST (18%)</Title>
        <List>
          <ListItem>● Four complimentary delegate passes</ListItem>
          <ListItem>● 15 minutes for presentation</ListItem>
          <ListItem>● Complimentary exhibition booth</ListItem>
          <ListItem>● Hotel accommodation (2 nights)</ListItem>
          <ListItem>● Prominent display of logo on all printed material, backdrop, and website</ListItem>
          <ListItem>● Permission to keep company brochures in registration kits</ListItem>
        </List>
      </Card>

      <Card bgColor="#c8e6c9">
        <Title>Silver – 2 lakhs+GST (18%)</Title>
        <List>
          <ListItem>● Three complimentary delegate passes</ListItem>
          <ListItem>● 10 minutes for presentation</ListItem>
          <ListItem>● Complimentary exhibition booth</ListItem>
          <ListItem>● Hotel accommodation (2 nights)</ListItem>
          <ListItem>● Prominent display of logo on all printed material, backdrop, and website</ListItem>
        </List>
      </Card>

      <Card bgColor="#f0f4c3">
        <Title>Bronze – 1 lakh+GST (18%)</Title>
        <List>
          <ListItem>● Two complimentary delegate passes</ListItem>
          <ListItem>● 5 minutes for presentation</ListItem>
          <ListItem>● Complimentary exhibition booth</ListItem>
          <ListItem>● Prominent display of logo on all printed material, backdrop, and website</ListItem>
        </List>
      </Card>

      <Card bgColor="#f8bbd0">
        <Title>Exhibition: INR 0.5 L + GST (18%)</Title>
        <List>
          <ListItem>● One complimentary delegate pass</ListItem>
          <ListItem>● Table-top for display Logo printed in conference brochure and website</ListItem>
          <ListItem>● Recognition during the Opening Speech at the Awards Presentation</ListItem>
        </List>
      </Card>
    </SponsorshipContainer>
  );
};

export default SponsorShipComp;
