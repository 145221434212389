import React from 'react'
import Navbar from '../Components/Navbar'
import Slider from '../Components/Slider'
import AboutWams from '../Components/AboutWams'
import AboutIIITDM from '../Components/AboutIIITDM'
import Footer from '../Components/Footer'
import ImportantDate from '../Components/ImportantDate'


const Home = () => {

  return (
    <>
      <Navbar/>
      <Slider/>
      <AboutWams/>
      <AboutIIITDM/>
      <ImportantDate/>
      <Footer/>
    </>
  )
}

export default Home