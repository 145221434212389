import React from 'react';
import styled from 'styled-components';

const PromotionContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  
`;

const Heading = styled.h2`
  text-align: center;
  color: #0047AB;
  margin-bottom: 20px;
`;

const PromoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const PromoCard = styled.div`
  background-color: #d1e7ff;
  color: black;
  padding: 20px;
  border-radius: 8px;
  width: 48%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const Title = styled.h3`
  color: #D10F0F;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const PromotionComp = () => {
  return (
    <PromotionContainer>
      <Heading>PROMOTION AND ADVERTISING</Heading>
      
      <PromoWrapper>
        <PromoCard>
          <Title>Awards Dinner Banquet: INR 5.0 L + GST (18%)</Title>
          <List>
            <ListItem>● Six complimentary delegate passes</ListItem>
            <ListItem>● 30 Minutes Slot for presentation during the conference</ListItem>
            <ListItem>● Sponsor’s logo will be displayed in the banquet hall</ListItem>
            <ListItem>● Recognition during the Opening Speech at the Awards Presentation</ListItem>
            <ListItem>● Conference pamphlet and the symposium website will include the sponsor’s name and logo</ListItem>
            <ListItem>● Hotel accommodation (3 nights)</ListItem>
          </List>
        </PromoCard>

        <PromoCard>
          <Title>Delegate Bags and Conference Brochure: INR 2.5 L + GST (18%)</Title>
          <List>
            <ListItem>● Four complimentary delegate passes</ListItem>
            <ListItem>● The sponsor’s logo on the delegate bag with the WAMS logo</ListItem>
            <ListItem>● Recognition at the awards banquet, website, and conference flyer</ListItem>
            <ListItem>● Hotel accommodation (2 nights)</ListItem>
          </List>
        </PromoCard>
      </PromoWrapper>
    </PromotionContainer>
  );
};

export default PromotionComp;
