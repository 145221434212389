import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
    width : 100vw;
    height : 100vh;
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
`
const Title = styled.h2`
    font-size:  40px;
    color : #214B8C;
`
const Description = styled.div`
   font-size: 20px;
   color : #214B8C;
`
const Button = styled.button`
    border : none;
    color : white;
    margin : 50px 0px;
    padding : 15px 22px;
    font-size : 18px;
    background-color : #214B8C;
    font-weight : bold;
    border-radius : 10px;
    cursor : pointer;
    transition :  all 0.25s ease-out;
    &:hover{
        transform : scale(1.02);
        color : #214B8C;
        background-color : white;
        box-shadow : 0px 0px 10px 2px #214B8C;
        transition :  all 0.25s ease-in;
    }
`

const TempWorking = () => {
  return (
    <Container>
        <Title>Sorry! Work In Progress</Title>
        <Description>This page is still under construction.</Description>
        <Link style={{textDecoration : "none", zIndex : "2"}} to = "/" > <Button>Home Page</Button> </Link>
    </Container>
  )
}

export default TempWorking