import React from 'react';
import styled from 'styled-components';
import { CommitteeData } from '../data';


const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
`;

const Title = styled.h2`
  font-size: 32px;
  color: #214B8C;
  margin-bottom: 30px;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const Designation = styled.h3`
  font-size: 24px;
  color: #214B8C;
  text-align: center;
  margin-bottom: 20px;
`;

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const Card = styled.div`
  width: 100%;
  max-width: 220px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #214B8C;
  overflow: hidden;
  text-align: center;
  padding: 15px;
  margin: 10px;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Name = styled.h4`
  font-size: 18px;
  color: #214B8C;
  margin-bottom: 8px;
`;

const Role = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
`;

const Represents = styled.p`
  font-size: 12px;
  color: #888;
`;

const CommitteeComp = () => {
  return (
    <Container>
      <Title>Committee</Title>
      {CommitteeData.map(item => (
        <Section key={item.id}>
          <Designation>{item.designation}</Designation>
          <CardGrid>
            {item.details.map(detail => (
              <Card key={detail.name}>
                <Image src={detail.image} alt={detail.name} />
                <Name>{detail.name}</Name>
                <Role>{detail.chair}</Role>
                <Represents>{detail.represent}</Represents>
              </Card>
            ))}
          </CardGrid>
        </Section>
      ))}
    </Container>
  );
};

export default CommitteeComp;
