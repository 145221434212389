import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
    padding : 20px;
`
const WrapperLocation = styled.div`
    display : flex;
    justify-content : center;
    flex-direction : column;
    align-items : center;
`
const WrapperDirection = styled.div`
    display : flex;
    justify-content : center;
    flex-direction : column;
    align-items : center;
`
const Title = styled.h2`
    font-size : 22px;
    text-align : center;
    color: #214B8C;
`
const Description = styled.p`
    font-size : 18px;
    text-align : center;
    width : 70%;
    color: #214B8C;
`
const BusDetails = styled.p`
    font-size : 18px;
    text-align : center;
    width : 70%;
    color: #214B8C;
`
const Buses = styled.p`
    font-size : 18px;
    text-align : center;
    width : 70%;
    color: #214B8C;
`
const Heading = styled.h2`
    font-size : 30px;
    text-align : center;
    color: #214B8C;
    text-decoration : underline;
`
const ExploreMore = styled.p`
    font-size : 20px;
    text-align : center;
    color: #214B8C;
    font-weight : bold;
`
const Button = styled.button`
    border : none;
    color : white;
    padding : 10px 17px;
    font-size : 18px;
    background-color : #214B8C;
    font-weight : bold;
    border-radius : 10px;
    cursor : pointer;
    transition :  all 0.25s ease-out;
    &:hover{
        transform : scale(1.02);
        color : #214B8C;
        background-color : white;
        box-shadow : 0px 0px 10px 2px #214B8C;
        transition :  all 0.25s ease-in;
    }
`

const Traveldetails = () => {
  return (
    <Container>
        <WrapperLocation>
            <Heading>Location of Institute</Heading>
            <Description>Indian Institute of Information Technology, Design & Manufacturing (IIITD&M) Kancheepuram Melakottaiyur Village Off Vandalur-Kelambakkam Road Chennai - 600 127 Tamil Nadu, India</Description>
        </WrapperLocation>
        <Heading>Route to Institute</Heading>
        <WrapperDirection>
            <Title>From Tambaram</Title>
            <Description>Institute is located in Melakottaiyur, which is 10km from Tambaram Railway station.</Description>
            <BusDetails>The nearest bus stop is KANDIGAI. Our Institute is 1.5km walk from Kandigai.Auto fare from Kandigai to the institute is Rs. 50. There is exactly one direct bus from Tambaram to IIIT which is 55D which runs every hour from Tambaram terminus.
            </BusDetails>
            <Buses><b>Buses: </b>515, 517, 555, 566, 55D, 55C, 115, 55K</Buses>
        </WrapperDirection>
        <WrapperDirection>
            <Title>From Chennai Central Railway Station</Title>
            <Description>Reach Tambaram from Central Railway Station either by bus or Chennai Suburban Railway Train.</Description>
            <BusDetails>From Chennai Central, reach Park station, which is 10min walk. At Park station, catch Tambaram bound train and you will reach Tambaram in 45 min.
            </BusDetails>
            <Buses><b>Buses: </b>1B, 579, A18, B18, E18</Buses>
        </WrapperDirection>
        <WrapperDirection>
            <Title>From Tambaram</Title>
            <Description>The bus stop is just across the road from the airport. All the buses from CMBT and Central Railway Station pass through this bus stop and if you take any of bus heading to Tambaram.</Description>
            <BusDetails>Most importantly, Chennai being a metropolitan city, we have several number of cabs (say OLA,UBER, FASTRACK) connecting destinations of people throughout the day accomplishing ones journey with reliability and comfort.
            </BusDetails>
        </WrapperDirection>
        <ExploreMore>Explore more details here: 
        <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://www.iiitdm.ac.in/maps-and-directions/from-airport" target='_blank'> <Button>Explore</Button> </Link>
        </ExploreMore>
    </Container>
  )
}

export default Traveldetails;