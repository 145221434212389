import React from 'react';
import styled from 'styled-components';
import WamsLogo from '../assets/WAMSLogo.png';
import { HashLink as Link } from 'react-router-hash-link';

const FooterContainer = styled.footer`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 20px;
  background-color: #efefef;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const FooterColumn = styled.div`
  text-align: center;
`;

const FooterTitle = styled.h3`
  text-decoration: underline;
  font-size: 22px;
  color: #214b8c;
`;

const FooterList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  line-height: 5px;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

const FooterListItem = styled.li`
  cursor: pointer;
  color: #214b8c;
  padding: 4px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  transition: all 0.25s ease-out;
  &:hover {
    transform: scale(1.1);
    transition: all 0.25s ease-in;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    color: black;
  }
`;

const Copyright = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #214b8c;
  margin: 0;
  padding : 10px 0px;
`;

const Left = styled.p`
  text-align: center;
  margin: 0;
  color: #efefef;
  flex: 1;
`;

const Wams = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: span 2;

  @media (max-width: 768px) {
    grid-column: span 1;
  }
`;

const Logo = styled.img`
  width: 55%;
`;

const Info = styled.div`
  margin-left: 20px;
`;

const Title = styled.h3`
  font-size: 30px;
  color: #214b8c;
`;

const Desc = styled.p`
  font-weight: 550;
  color: #214b8c;
`;

function Footer() {
  return (
    <>
      <FooterContainer>
        <Wams>
          <Logo src={WamsLogo} />
          <Info>
            <Title>WAMS 2025</Title>
            <Desc>June 5-8, 2025</Desc>
            <Desc>Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram</Desc>
          </Info>
        </Wams>
        <FooterColumn>
          <FooterTitle>Useful Links</FooterTitle>
          <FooterList>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="https://www.iiitdm.ac.in/" target='_blank'>
              <FooterListItem>IIITDM Kancheepuram</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="https://wams2024.com/" target='_blank'>
              <FooterListItem>WAMS 2024</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="https://wams-society.com/wams-2023/" target='_blank'>
              <FooterListItem>WAMS 2023</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="https://wams-society.com/wams-2022/" target='_blank'>
              <FooterListItem>WAMS 2022</FooterListItem>
            </Link>
          </FooterList>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>Quick Links</FooterTitle>
          <FooterList>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="/home#top">
              <FooterListItem>Home</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="https://wams-society.com/s" target='_blank'>
              <FooterListItem>WAMS Society</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="/work-in-progress">
              <FooterListItem>Registration</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="https://wams-society.com/wams-membership/" target='_blank'>
              <FooterListItem>WAMS Membership</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="/committee#top">
              <FooterListItem>Committees</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="/submit-a-paper#top">
              <FooterListItem>IEEE Students Awards</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="/work-in-progress">
              <FooterListItem>Speakers</FooterListItem>
            </Link>
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="/nearby-attractions#top">
              <FooterListItem>Nearby Attractions</FooterListItem>
            </Link>
          </FooterList>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>Get In Touch</FooterTitle>
          <FooterList>
            <FooterListItem>IIITDM Kancheepuram <br /> Off Vandalur-Kelambakkam Road <br /> Chennai-600127</FooterListItem>
            <hr />
            <FooterListItem>+91-44-27476300 <br /> +91-9444165638</FooterListItem>
            <hr />
            <FooterListItem>wams2025@iiitdm.ac.in</FooterListItem>
            <hr />
            <Link style={{ textDecoration: "none", zIndex: "2" }} to="/contact#top">
              <FooterListItem>{`Contact Us >>`}</FooterListItem>
            </Link>
          </FooterList>
        </FooterColumn>
      </FooterContainer>
      <Copyright>
        <Left>Copyright © WAMS 2025 - All Rights Reserved.</Left>
      </Copyright>
    </>
  );
}

export default Footer;
