import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import SponsorCard from '../Components/Sponsors'

const SponsorPage = () => {
  return (
    <>
    <Navbar/>
    <SponsorCard/>
    <Footer/>
    </>
  )
}

export default SponsorPage