import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Staying from '../Components/Staying'

const ContactUs = () => {
  return (
    <>
    <Navbar/>
    <Staying/>
    <Footer/>
    </>
  )
}

export default ContactUs