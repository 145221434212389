import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Wams1 from '../assets/Wamsphoto/wams1.jpg';
import Wams2 from '../assets/Wamsphoto/wams2.jpeg';
import Wams3 from '../assets/Wamsphoto/wams3.jpeg';
import { maxitab } from '../responsive';

const Container = styled.div`
    width: 98.5vw;
    height: auto;
    position: relative;
`;

const Wrapper = styled.div`
    margin: 3% 0px;
    width: 99%;
    display: flex;
    ${maxitab({
        flexDirection: "column",
    })}
`;

const InfoContainer = styled.div`
    padding: 2% 2%;
    background-color: #efefef;
    flex: 1.2;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: white; 
    }
    &::-webkit-scrollbar-thumb {
        background: #214B8C;
        border-radius: 10px;
    }
    border-top: 10px solid #214B8C;
`;

const Title = styled.h2`
    font-size: 35px;
    color: #214B8C;
    display: flex;
`;

const Desc1 = styled.p`
    width: 95%;
    line-height: 25px;
    text-align: justify;
    color: #214B8C;
`;

const Button = styled.button`
    border: none;
    color: white;
    padding: 15px 22px;
    font-size: 18px;
    background-color: #214B8C;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.25s ease-out;
    &:hover {
        transform: scale(1.02);
        color: #214B8C;
        background-color: white;
        box-shadow: 0px 0px 10px 2px #214B8C;
        transition: all 0.25s ease-in;
    }
`;

const SliderContainer = styled.div`
    flex: 1;
    width: auto;
    display: flex;
    overflow: hidden;
    position: relative;
    background-color: black;
`;

const SliderWrapper = styled.div`
    display: flex;
`;

const Slide = styled.img`
    width: 50vw;
    object-fit: cover;
    transition: all 1.5s ease;
    transform: translateX(${props => props.index * -50}vw);
    ${maxitab(css`
        transform: translateX(${props => props.index * -25}vw);
    `)}
`;

const AboutWams = () => {
    const [index, setIndex] = useState(0);
    
    const images = [Wams1, Wams2, Wams3];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Container id='AboutWams'>
            <Wrapper>
                <InfoContainer>
                    <Title>| About WAMS 2025</Title>
                    <Desc1>
                        Wireless, Antenna and Microwave Symposium (WAMS) is planned as a multidisciplinary symposium held annually in an academic institute. It is focused on providing an affordable yet state-of-the-art international forum for students, and young professionals to interact with the leading domain experts from around the world. The 4th edition of WAMS, WAMS 2025 will be held at the Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram located near Chennai, the gateway city of South India, during June 5th to 8th, 2025. WAMS 2025 is intended to provide a forum for sharing and exchange of information on the latest, cutting-edge research and scientific achievements in wireless, antennas, microwave engineering, and sciences along with establishing and strengthening professional networks.
                    </Desc1>
                    <Link style={{ textDecoration: "none", zIndex: "2" }} to="https://wams-society.com/" target='_blank'>
                        <Button>Explore more</Button>
                    </Link>
                </InfoContainer>
                <SliderContainer>
                    {images.map((image, idx) => (
                        <SliderWrapper key={idx}>
                            <Slide src={image} index={index} />
                        </SliderWrapper>
                    ))}
                </SliderContainer>
            </Wrapper>
        </Container>
    );
};

export default AboutWams;
